import React, { Component } from 'react';
import { Form } from './Form';
import { Results } from './Results';
import { Button } from '../elements/Button';
import styles from './Content.module.scss';
import scrollToComponent from 'react-scroll-to-component';
import * as constants from '../constants';

export class Content extends Component {

	constructor(props) {
		super(props);

		this.pay100Options = [
			{ value: '0', label: '1 week' },
			{ value: '1', label: '1 month' },
			{ value: '2', label: '2 months' },
			{ value: '3', label: '3 months' },
			{ value: '6', label: '6 months' },
			{ value: '12', label: '12 months' },
			{ value: '24', label: '24 months' },
			{ value: '60', label: '60 months' },
		];

		this.all50Options = [];
		this.all50Options[0] = [
			{ value: '1', label: '1 month' },
			{ value: '2', label: '2 months' },
			{ value: '3', label: '3 months' },
			{ value: '6', label: '6 months' },
			{ value: '12', label: '12 months' },
			{ value: '24', label: '24 months' },
			{ value: '60', label: '60 months' },
		];
		this.all50Options[1] = [
			{ value: '0', label: '0 months' },
			{ value: '1', label: '1 month' },
			{ value: '2', label: '2 months' },
			{ value: '5', label: '5 months' },
			{ value: '11', label: '11 months' },
			{ value: '23', label: '23 months' },
			{ value: '59', label: '59 months' },
		];
		this.all50Options[2] = [
			{ value: '0', label: '0 months' },
			{ value: '1', label: '1 month' },
			{ value: '4', label: '4 months' },
			{ value: '10', label: '10 months' },
			{ value: '22', label: '22 months' },
			{ value: '58', label: '58 months' },
		];
		this.all50Options[3] = [
			{ value: '0', label: '0 months' },
			{ value: '3', label: '3 months' },
			{ value: '9', label: '9 months' },
			{ value: '21', label: '21 months' },
			{ value: '57', label: '57 months' },
		];
		this.all50Options[6] = [
			{ value: '0', label: '0 months' },
			{ value: '6', label: '6 months' },
			{ value: '24', label: '18 months' },
			{ value: '60', label: '54 months' },
		];
		this.all50Options[12] = [
			{ value: '0', label: '0 months' },
			{ value: '12', label: '12 months' },
			{ value: '48', label: '48 months' },
		];
		this.all50Options[24] = [
			{ value: '0', label: '0 months' },
			{ value: '36', label: '36 months' },
		];
		this.all50Options[60] = [
			{ value: '0', label: '0 months' },
		];

		this.pay50Options = this.all50Options[6]; // default
		this.submittedVals = {
			[constants.INCOME]: 0,
			[constants.PAY_100]: this.pay100Options[4],
			[constants.PAY_50]: this.pay50Options[1]
		};

		this.state = { ...this.submittedVals, submitCount: 0 };
		this.handleInputChange = this.handleInputChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.clickLogin = this.clickLogin.bind(this);
	}

	handleInputChange(e) {
		let target = e.target,
			value = target.value;

		if (target.name === constants.INCOME && isNaN(Number(value.replace(',', '')))) {
			value = this.state.income;
		}

		if (target.name === constants.PAY_100) {
			this.pay50Options = this.all50Options[value.value];
			this.setState({
				[constants.PAY_50]: this.pay50Options[0]
			})
		}

		this.setState({
			[target.name]: value
		});
	}

	submitForm(e) {
		e.preventDefault();
		// on submit, copy the state values for the results panel
		this.submittedVals = { ...this.state };
		// requires something to change in state as well, to prompt immediate redraw
		this.setState((prevState) => ({
			submitCount: prevState.submitCount + 1
		}));
		scrollToComponent(this.resultsRef, {
			offset: 0,
			align: 'top',
			duration: 500
		});
	}

	clickLogin() {
		window.open('https://adviser.vitality.co.uk/life/online/manualLogin', '_blank');
	}

	render() {

		var today = new Date(),
			date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

		return (
			<main className={styles.main} id="main">
				<div className={[styles.padding, "js-no-download"].join(' ')}>
					<p className={styles.p}>Our simple Dual Deferred Period Calculator enables you to work out the maximum benefit amount that you’re able to select based on your client’s sick pay scheme.</p>
					<Form onChange={this.handleInputChange} onSubmit={this.submitForm} pay100Options={this.pay100Options} pay50Options={this.pay50Options} {...this.state} />
				</div>

				<div className={[styles.padding, styles.resultBased, "u-hidden", "u-clearfix", "js-download"].join(' ')}>
					<h3 className={[styles.h3, styles.preparedHeader].join(' ')}>Prepared on: <span className="u-text-highlight">{date}</span></h3>
					<p className={styles.subHead}>Results are based on:</p>
					<h4 className={styles.h4}><span className="u-text-highlight">Annual income:</span> £{this.submittedVals[constants.INCOME]}</h4>
					<h4 className={styles.h4}><span className="u-text-highlight">Duration of 100% Sick Pay:</span> {this.submittedVals[constants.PAY_100].label}</h4>
					<h4 className={styles.h4}><span className="u-text-highlight">Duration of 50% Sick Pay:</span> {this.submittedVals[constants.PAY_50].label}</h4>
				</div>

				<hr className={styles.resultsBorder} />

				<div className={[styles.background, styles.padding, styles.pinkBox, "u-clearfix"].join(' ')} ref={(section) => { this.resultsRef = section; }}>
					<Results {...this.submittedVals} />
				</div>

				<div className={[styles.padding, styles.border, "js-no-download", "u-text-center"].join(' ')}>
					<h3 className={styles.h3}>Login to <span className="u-text-highlight">Adviser Hub</span></h3>
					<p className={styles.p}>Making it easier to do business with us online.</p>
					<Button name="Login" id="loginBtn" onClick={this.clickLogin} />
				</div>

				<div className={[styles.padding, styles.termsBox].join(' ')}>
					<div className={styles.outline}>
						<p className={styles.p}>
							<small className={styles.small}>
								<a href="https://adviser.vitality.co.uk/life-insurance/personal/income-protection/?_ga=2.50694317.54421100.1668789434-840551259.1655723052" className={[styles['pink-underlined'], 'js-no-download'].join(' ')}>Find out more about our Income Protection Cover on our adviser website.</a>
								<span className="u-hidden js-download"><span className={styles['pink-underlined']}>Find out more about our Income Protection Cover on our adviser website.</span> <br />https://adviser.vitality.co.uk/life-insurance/personal/income-protection/.</span>
							</small>
						</p>
						<p className={styles.p}><small className={styles.small}>An individual will get their first payment at the end of their deferred period, which they set when they take out their plan. The deferred period will start on the first day the individual is unable to work and ends when they have been continuously incapacitated for either:</small></p>
						<p className={styles.p}><small className={styles.small}>&#9679; 1 month &#9679; 2 months &#9679; 3 months &#9679; 6 months &#9679; 12 months &#9679; 24 months &#9679; 60 months</small></p>
					</div>
				</div>
			</main>
		)
	}
}