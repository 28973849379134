import 'promise-polyfill/src/polyfill';
import 'core-js/es6/map';
import 'core-js/es6/set';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

require('es6-object-assign').polyfill();

if (!window.requestAnimationFrame) {
	window.requestAnimationFrame = function(callback) {
		setTimeout(callback, 0);
	};
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
