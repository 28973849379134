import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {TextInput} from '../elements/TextInput';
import NumberFormat from 'react-number-format';
import {Button} from '../elements/Button';
import {Label} from '../elements/Label';
import {Select} from '../elements/Select';
import styles from './Form.module.scss';
import * as constants from '../constants';

export class Form extends Component {

	constructor(props) {
		super(props);
		this.handle100Change = this.handle100Change.bind(this);
		this.handle50Change = this.handle50Change.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	handle100Change(e) {
		this.props.onChange({target: {value: {...e}, name: constants.PAY_100}});
	}

	handle50Change(e) {
		this.props.onChange({target: {value: {...e}, name: constants.PAY_50}});
	}

	/* Clear value if default is 0 */
	handleFocus(e) {
		var value = e.target.value;
		if(value === '0') {
			e.target.value = '';
		}
	}

	/* Return default value if nothing is entered */
	handleBlur(e) {
		var value = e.target.value;
		if(value === '') {
			e.target.value = '0';
		}
	}

	render() {
		const buttonEnabled = Number(String(this.props.income).replace(',','')) > 0;

		return (
			<form onSubmit={this.props.onSubmit}>
				<div className={[styles['input-wrapper'], styles['input-wrapper--lg']].join(' ')}>
					<Label for={constants.INCOME} enter="Enter" text="Annual Income" />
					<NumberFormat customInput={TextInput}  thousandSeparator={true} className={styles.input} name={constants.INCOME} value={this.props[constants.INCOME]} onFocus={this.handleFocus} onChange={this.props.onChange} onBlur={this.handleBlur}/>
				</div>
				<div className={[styles['input-wrapper'], styles['input-wrapper--sm']].join(' ')}>
					<Label for={constants.PAY_100} enter="Choose" text="duration of 100% Sick Pay" />
					<Select options={this.props.pay100Options} onChange={this.handle100Change} value={this.props[constants.PAY_100]} placeholder="Select an option" />
				</div>
				<div className={[styles['input-wrapper'], styles['input-wrapper--sm']].join(' ')}>
					<Label for={constants.PAY_50} enter="Choose" text="duration of 50% Sick Pay" />
					<Select options={this.props.pay50Options} onChange={this.handle50Change} value={this.props[constants.PAY_50]} placeholder="Select an option" />
				</div>
				<div className="u-text-center">
					<Button disabled={!buttonEnabled} name="Calculate" id="calculateBtn" />
				</div>
			</form>
		)
	}
}

Form.propTypes = {
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	pay100Options: PropTypes.arrayOf(PropTypes.object).isRequired,
	pay50Options: PropTypes.arrayOf(PropTypes.object).isRequired,
	income: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]).isRequired,
	pay100: PropTypes.object.isRequired,
	pay50: PropTypes.object.isRequired
}