import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Intro.scss';

export class Intro extends Component {
	render() {
		return (
			<header className="intro js-no-download">
		        <div className="u-wrapper">
		            {this.props.text}
		        </div>
		    </header>
		)
	}
}

Intro.propTypes = {
    text: PropTypes.string.isRequired
}