import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Download} from './Download';
import styles from './Results.module.scss';
import * as constants from '../constants';

export class Results extends Component {
	render() {

		function round(num) {
			return Math.round(num * 100) / 100;
		}

		var b4 = Number(String(this.props.income).replace(',','')),
			n7 = b4 ? b4/2: 0,
			n8Options = {'comp': 200000, 'primary': 120000, 'short': 120000},
			n8 = n8Options['primary'],
			n6 = b4 > 60000 ? (b4 - 60000) * 0.5 + 36000 : b4 * 0.6,
			b10 = Math.min(n8,n6-(n7*0.6)),
			b11 = Math.min(n8,n6)-b10,
			b12 = b10 + b11,
			c10 = round(b10/12),
			c11 = round(b11/12),
			c12 = round(b12/12),
			disabled = b4 > 0;

			// Add thousand seperators
			b10 = Number(b10).toLocaleString();
			b11 = Number(b11).toLocaleString();
			b12 = Number(b12).toLocaleString();
			c10 = Number(c10).toLocaleString();
			c11 = Number(c11).toLocaleString();
			c12 = Number(c12).toLocaleString();

		return (
			<>
				<h3 className="results-header">Dual Deferred Period Report</h3>
				<div className={styles.box}>
					<h4 className={styles.h4}>Maximum <span className={styles['h4--highlighted']}>Monthly</span> Benefit</h4>
					<h5 className={styles.h5}>Initial deferred period of <b>{this.props[constants.PAY_100].label}</b></h5>
					<h6 className={styles.h6}>£{c10}</h6>
					<h5 className={styles.h5}>Additional deferred period of <b>{this.props[constants.PAY_50].label}</b></h5>
					<h6 className={styles.h6}>£{c11}</h6>
					<hr className={styles.hr} />
					<h5 className={styles.h5}>Total cover amount</h5>
					<h6 className={[styles.h6, styles['h6--highlighted']].join(' ')}>£{c12}</h6>
				</div>
				<div className={styles.box}>
					<h4 className={styles.h4}>Maximum <span className={styles['h4--highlighted']}>Annual</span> Benefit</h4>
					<h5 className={styles.h5}>First Plan with deferred period of <b>{this.props[constants.PAY_100].label}</b></h5>
					<h6 className={styles.h6}>£{b10}</h6>
					<h5 className={styles.h5}>Additional deferred period of <b>{this.props[constants.PAY_50].label}</b></h5>
					<h6 className={styles.h6}>£{b11}</h6>
					<hr className={styles.hr} />
					<h5 className={styles.h5}>Total cover amount</h5>
					<h6 className={[styles.h6, styles['h6--highlighted']].join(' ')}>£{b12}</h6>
				</div>
				<div className="download-button u-text-center">
					<Download disabled={!disabled} />
				</div>
			</>
		)
	}
}

Results.propTypes = {
	income: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]).isRequired
}