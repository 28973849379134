import React, {Component} from 'react';
import './Footer.scss';

export class Footer extends Component {
	render() {
		return (
			<footer className="footer" id="footer">
				<div className="u-wrapper">
					<div className="footer__social-container js-no-download">
						<a className="footer__social-link" href="https://www.facebook.com/VitalityUK" target="_blank" rel="noopener noreferrer">
							<i aria-hidden="true" className="fa fa-facebook-f"></i>
						</a>
						<a className="footer__social-link" href="https://twitter.com/VitalityAdviser" target="_blank" rel="noopener noreferrer">
							<i aria-hidden="true" className="fa fa-twitter"></i>
						</a>
						<a className="footer__social-link" href="https://www.youtube.com/user/livewithVitality" target="_blank" rel="noopener noreferrer">
							<i aria-hidden="true" className="fa fa-youtube"></i>
						</a>
						<a className="footer__social-link" href="https://www.instagram.com/vitality_uk/" target="_blank" rel="noopener noreferrer">
							<i aria-hidden="true" className="fa fa-instagram"></i>
						</a>
					</div>
					<p><small>VitalityLife is a trading name of Vitality Corporate Services Limited. Registered number 05933141. Registered in England and Wales. Registered office at 3 More London Riverside, London, SE1 2AQ. Vitality Corporate Services Limited is authorised and regulated by the Financial Conduct Authority.</small></p>
					<p><small>Calls may be recorded/monitored to help improve customer service.</small></p>
				</div>
			</footer>
		)
	}
}