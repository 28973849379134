import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../elements/Button';
import { Loader } from '../elements/Loader';

export class Download extends Component {

	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.origViewport = '';
		this.state = {
			loading: false
		}
		this.viewport = document.querySelector("meta[name=viewport]");
	}

	onClick() {
		this.setState({
			loading: true
		});

		this.buildPDF();
	}

	buildPDF() {
		html2canvas(document.body, {
			onclone: function (document) {
				var body = document.body;
				body.classList.add('print-view');
			},
			windowWidth: 1200,
			windowHeight: 1400,
			scrollY: 0,
			width: 1200,
		}).then(canvas => {
			let canvasWidth = canvas.width;
			let canvasHeight = canvas.height;

			let ratio = canvasHeight / canvasWidth;
			const imgData = canvas.toDataURL('image/png', canvasWidth, canvasHeight);

			const pdf = new jsPDF('p', 'mm', 'a4', true);

			let width = pdf.internal.pageSize.getWidth();
			let height = width * ratio;

			pdf.addImage(imgData, 'JPEG', 0, 0, width, height, '', 'FAST');
			pdf.save('Dual-Deferred-Period-Report.pdf');

			this.setState({
				loading: false
			});
		});
	}

	render() {

		return (
			<>
				<Button disabled={this.props.disabled} onClick={this.onClick} name="Download report" inverse={true} id="downloadBtn" />
				{this.state.loading &&
					<Loader hideAll={false} />
				}
			</>
		)
	}
}

Download.propTypes = {
	disabled: PropTypes.bool
}