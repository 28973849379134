import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

export function Button(props) {
	var classes = 'js-no-download' + (props.inverse ? ' inverse' : '');
	return <button id={props.id} className={classes} disabled={props.disabled} onClick={props.onClick}>{props.name}</button>
}

Button.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	inverse: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func
}