import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import './Select.scss';

export function Select(props) {
	return <Dropdown options={props.options} onChange={props.onChange} value={props.value} placeholder={props.placeholder} />
}

Select.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string
	}).isRequired
}