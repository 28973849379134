import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Header.scss';

export class Header extends Component {
    render() {
        return (
            <header className="header">
            	<div className="u-wrapper">
            		<div className="header__logoLockup">
		                <a className="header__logo" href="https://adviser.vitality.co.uk/life-insurance/?_ga=2.40799328.338078701.1548629297-1884880139.1540224441" target="_blank" rel="noopener noreferrer" id="logo">Vitality Life</a>
		                <img className="header__sublogo" src="assets/images/defaqto-2021.png" width="80" height="51" alt="Defaqto" />
	                </div>
	                <h1>{this.props.title}</h1>
                    <h2 className="js-no-download">{this.props.tagline}</h2>
	            </div>
            </header>
        )
    }
}

Header.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]).isRequired,
    tagline: PropTypes.string
}