import React from 'react';
import PropTypes from 'prop-types';
import styles from './TextInput.module.scss';

export function TextInput(props) {
    return (
            <div className={styles.wrapper}>
                £ <input type="text" id={props.name} name={props.name} onFocus={props.onFocus} onKeyUp={props.onKeyUp} onBlur={props.onBlur} onChange={props.onChange} value={props.value} className={styles.input} />
            </div>
        )
}

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired
}