import React from 'react';
import PropTypes from 'prop-types';
import styles from './Label.module.scss';

export function Label(props) {
	return <label className={styles.label} htmlFor={props.for}>{props.enter} <span className="u-text-highlight">{props.text}</span></label>
}

Label.propTypes = {
	for: PropTypes.string.isRequired,
	enter: PropTypes.string.isRequired,
	text: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]).isRequired,
}