import React, { Component } from 'react';
import {Header} from './components/Header';
import {Intro} from './components/Intro';
import {Footer} from './components/Footer';
import {Content} from './components/Content';
import './App.scss';


const pageTitle = (
	<span><span className="titleLine">Income Protection</span> <span className="titleLine">Dual Deferred Period Calculator</span></span>
);

class App extends Component {
  render() {
    return (
        <>
          <Header title={pageTitle} tagline="For Adviser use only." />
          <Intro text="Employer’s sick pay schemes will not always meet the needs of their employee’s lifestyle. This is where income protection can help bridge that gap." />
          <Content />
          <Footer />
        </>
    );
  }
}

export default App;
